






























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { GroupsActions } from '@/store/groups/actions.groups';
// import { GroupsGetter } from '@/store/groups/getter.groups';
import { ActionMethod } from '@/helpers/typeHelpers';
import {
  // UserAssignment,
  UpdateCertificationGroupMutationInput,
  // FetchAllUsersForHierarchyQueryVariables,
} from '@/generated/graphql';
import FullScreenLoading from '@/components/common/FullScreenLoading.vue';
import { CertificationGroupFrontEnd } from '@/store/groups/groups';

@Component({
  components: { FullScreenLoading },
})
export default class EditModal extends Vue {
  @Prop({
    required: false,
    default: () => ({}),
  })
  group!: CertificationGroupFrontEnd;

  dialog = false;
  name = '';
  // contact = null;
  ratio = null;
  loading = false;
  ratios = [
    { name: '10%', code: 0.1 },
    { name: '20%', code: 0.2 },
    { name: '30%', code: 0.3 },
    { name: '40%', code: 0.4 },
    { name: '50%', code: 0.5 },
    { name: '60%', code: 0.6 },
    { name: '70%', code: 0.7 },
    { name: '80%', code: 0.8 },
    { name: '90%', code: 0.9 },
    { name: '100%', code: 1.0 },
  ];

  created() {
    this.name = this.group?.name ? this.group?.name : '';
    // eslint-disable-next-line
    this.ratio = this.group?.ratio ? this.group.ratio : (null as any);
    // eslint-disable-next-line
    // this.contact = this.group?.contact ? { name: this.group?.contact.name, id: this.group?.contact.id } : (null as any);

    // if (this.group?.marketViewEntity?.id) {
    //   const id = parseInt(this.group.marketViewEntity.id);
    //   this.fetchContacts({ marketViewEntity: { id: id } });
    // }
  }

  // @Getter(`groups/${GroupsGetter.GET_USERS_FROM_HEN}`)
  // henUsers!: UserAssignment[];
  //
  // @Action(`groups/${GroupsActions.FETCH_ALL_USERS_FROM_HIERARCHY}`)
  // fetchContacts!: ActionMethod<FetchAllUsersForHierarchyQueryVariables, void>;

  @Action(`groups/${GroupsActions.EDIT_GROUP}`)
  editGroup!: ActionMethod<UpdateCertificationGroupMutationInput, void>;

  // get contacts() {
  //   return this.henUsers.map(user => user.user);
  // }

  get canEdit() {
    return this.name && this.ratio;
    // return this.name && this.contact && this.ratio;
  }

  open() {
    this.dialog = true;
  }

  edit() {
    this.loading = true;
    this.editGroup({
      id: this.group.id,
      name: this.name,
      // contact: this.contact,
      ratio: this.ratio,
    })
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
    this.dialog = false;

    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('edit', {
      event_category: 'group',
      event_label: 'edit group details',
      value: this.name,
    });
  }
}
