































import Vue from 'vue';
import IntegrationsSearch from '@/components/groups/common/Search.vue';
import IntegrationsTable from '@/components/groups/common/IntegrationsTable.vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from '@/helpers/typeHelpers';
import { GroupsActions } from '@/store/groups/actions.groups';
import { GroupsGetter } from '@/store/groups/getter.groups';

import {
  Integration,
  FetchIntegrationsQueryVariables,
  AddIntegrationsToCertificationGroupMutationInput,
  FetchCertificationGroupQueryVariables,
} from '@/generated/graphql';
import { CertificationGroupFrontEnd } from '@/store/groups/groups';

@Component({
  components: { IntegrationsSearch, IntegrationsTable },
})
export default class AddExistingModal extends Vue {
  @Prop({
    required: false,
    default: () => ({}),
  })
  group!: CertificationGroupFrontEnd;

  dialog = false;

  selected: Integration[] = [];
  showWarning = false;

  @Getter(`groups/${GroupsGetter.GET_INTEGRATIONS}`)
  integrations!: Integration[] | null;

  @Action(`groups/${GroupsActions.FETCH_INTEGRATIONS}`)
  performFetchIntegrations!: ActionMethod<FetchIntegrationsQueryVariables, void>;

  @Action(`groups/${GroupsActions.ADD_INTEGRATIONS_TO_GROUP}`)
  addIntegrations!: ActionMethod<AddIntegrationsToCertificationGroupMutationInput, void>;

  @Action(`groups/${GroupsActions.FETCH_GROUP}`)
  performFetchGroup!: ActionMethod<FetchCertificationGroupQueryVariables, void>;

  created() {
    const payload: FetchIntegrationsQueryVariables = {
      searchStr: '',
      status: 'active',
    };

    const mveId = this.group?.marketViewEntity?.id ? parseInt(this.group.marketViewEntity.id) : null;

    if (mveId) {
      payload.withCommonParent = { id: mveId };
    }

    this.performFetchIntegrations(payload);
  }

  get selectedIntegrations() {
    return this.selected.map(integration => ({ id: Math.floor(integration.id) }));
  }

  get multipleTreeIdsSelected() {
    return this.selectedMarketViewEntities.length > 1;
  }

  get selectedMarketViewEntities() {
    const mve = this.selected.reduce((acc, integration) => {
      if (integration?.marketViewEntity?.parentTreeId) {
        const id = Math.floor(integration.marketViewEntity.parentTreeId);
        if (acc.indexOf(id) == -1) {
          acc.push(id);
        }
      }
      return acc;
    }, [] as number[]);
    return mve;
  }

  searchIntegrations(filters: FetchIntegrationsQueryVariables) {
    const mveId = this.group?.marketViewEntity?.id ? parseInt(this.group.marketViewEntity.id) : null;
    if (mveId) {
      filters.withCommonParent = { id: mveId };
    }
    filters.status = 'active';
    this.performFetchIntegrations(filters);
  }

  updateSelected(items: Integration[]) {
    this.selected = items;

    if (this.multipleTreeIdsSelected) {
      this.showWarning = true;
    }
  }

  open() {
    this.dialog = true;
  }

  async add() {
    await this.addIntegrations({ id: this.group.id, integrations: this.selectedIntegrations });
    this.dialog = false;
    this.performFetchGroup({ id: this.group.id });

    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('add_integrations', {
      event_category: 'group',
      event_label: 'add integration to group',
      value: this.group.id,
    });
  }
}
