




























import Vue from 'vue';
import IntegrationsSearch from '@/components/groups/common/Search.vue';
import IntegrationsTable from '@/components/groups/common/IntegrationsTable.vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from '@/helpers/typeHelpers';
import { GroupsActions } from '@/store/groups/actions.groups';
import { GroupsGetter } from '@/store/groups/getter.groups';
import {
  Integration,
  FetchIntegrationsQueryVariables,
  StartCompleteRecertificationMutationVariables,
  FetchCertifiedGroupWorkflowsQueryVariables,
  Workflow,
} from '@/generated/graphql';
import { CertificationGroupFrontEnd } from '@/store/groups/groups';
import { Recertifiability } from '../../../generated/graphql';

@Component({
  components: { IntegrationsSearch, IntegrationsTable },
})
export default class SelectForRecert extends Vue {
  @Prop({
    required: false,
    default: () => ({}),
  })
  group!: CertificationGroupFrontEnd;

  dialog = false;

  selected: Integration[] = [];
  showWarning = false;
  shownWarningOnce = false;

  @Getter(`groups/${GroupsGetter.GET_GROUP_INTEGRATIONS}`)
  integrations!: Integration[] | null;

  @Action(`groups/${GroupsActions.FETCH_INTEGRATIONS}`)
  performFetchIntegrations!: ActionMethod<FetchIntegrationsQueryVariables, void>;

  @Action(`groups/${GroupsActions.START_GROUP_RECERTIFICATION}`)
  performCreateCompleteRecertification!: ActionMethod<StartCompleteRecertificationMutationVariables, void>;

  @Action(`groups/${GroupsActions.FETCH_CERTIFIED_WORKFLOWS}`)
  performFetchCertifiedWorkflows!: ActionMethod<FetchCertifiedGroupWorkflowsQueryVariables, Workflow[]>;

  highlight: number[] = [];

  async created() {
    const payload: FetchIntegrationsQueryVariables = {
      searchStr: '',
    };

    const mveId = this.group?.marketViewEntity?.id ? parseInt(this.group.marketViewEntity.id) : null;

    if (mveId) {
      payload.withCommonParent = { id: mveId };
    }

    this.performFetchIntegrations(payload);

    const wfs = await this.performFetchCertifiedWorkflows({ id: this.group?.id || 0 });
    this.highlight = wfs?.map(wf => wf.integrationId || 0) || [];
  }

  get validIntegrations() {
    return this.integrations?.filter(integration => integration?.recertifiable == Recertifiability.Recertifiable);
  }

  get selectedIntegrations() {
    return this.selected.map(integration => ({ id: Math.floor(integration.id) }));
  }

  get multipleTreeIdsSelected() {
    return this.selectedMarketViewEntities.length > 1;
  }

  async alreadyCertified() {
    const already = await this.performFetchCertifiedWorkflows({ id: this.group?.id || 0 });
    return already?.map(wf => wf.integrationId);
  }

  get selectedMarketViewEntities() {
    const mve = this.selected.reduce((acc, integration) => {
      if (integration?.marketViewEntity?.parentTreeId) {
        const id = Math.floor(integration.marketViewEntity.parentTreeId);
        if (acc.indexOf(id) == -1) {
          acc.push(id);
        }
      }
      return acc;
    }, [] as number[]);
    return mve;
  }

  get needed() {
    return Math.max(Math.ceil(this.group.integrations.length * (this.group.ratio || 1.0)), 1);
  }

  searchIntegrations(filters: FetchIntegrationsQueryVariables) {
    const mveId = this.group?.marketViewEntity?.id ? parseInt(this.group.marketViewEntity.id) : null;
    if (mveId) {
      filters.withCommonParent = { id: mveId };
    }

    this.performFetchIntegrations(filters);
  }

  updateSelected(items: Integration[]) {
    this.selected = items;

    if (!this.shownWarningOnce) {
      const len = this.selected.length;
      let highlighted = false;
      for (let loop = 0; loop < len && !highlighted; ++loop) {
        highlighted = this.highlight.includes(this.selected[loop].id);
      }
      this.showWarning = highlighted;
      this.shownWarningOnce = highlighted;
    }
  }

  open() {
    this.dialog = true;
  }

  cleanup() {
    this.dialog = false;
    this.selected = [];
    this.shownWarningOnce = false;
  }

  recertify() {
    // this.addIntegrations({ id: this.group.id, integrations: this.selectedIntegrations });
    const payload: StartCompleteRecertificationMutationVariables = {
      integrations: this.selectedIntegrations.map(i => i.id.toString()),
    };
    this.performCreateCompleteRecertification(payload);

    this.dialog = false;

    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('recertify_group', {
      event_category: 'group',
      event_label: 'select integrations to recertify the group',
      value: this.group.id,
    });
  }
}
