

























import Vue from 'vue';
import CreateGroup from '@/components/groups/home/CreateModal.vue';
import GroupsSearch from '@/components/groups/common/Search.vue';
import GroupsSearchResultsTable from '@/components/groups/home/GroupsSearchResultsTable.vue';
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ActionMethod } from '@/helpers/typeHelpers';
import { GroupsActions } from '@/store/groups/actions.groups';
import { FetchCertificationGroupsQueryVariables } from '@/generated/graphql';

@Component({
  components: { CreateGroup, GroupsSearch, GroupsSearchResultsTable },
})
export default class Groups extends Vue {
  @Action(`groups/${GroupsActions.FETCH_GROUPS}`)
  performFetchGroups!: ActionMethod<FetchCertificationGroupsQueryVariables, void>;

  mounted() {
    this.performFetchGroups({ searchStr: '' });
    //@ts-ignore
    this.$gtag.pageview('/groups-home');
  }

  openDialog(method: () => void) {
    setTimeout(() => method());
  }

  searchGroups(filters: FetchCertificationGroupsQueryVariables) {
    this.performFetchGroups(filters);
    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('search', {
      event_category: 'groups',
      event_label: 'search groups from home',
    });
  }
}
