














import Vue from 'vue';
import GroupOverview from '@/components/groups/details/Overview.vue';
import IntegrationsSearch from '@/components/groups/details/Search.vue';
import GroupIntegrationsTable from '@/components/groups/details/GroupIntegrationsTable.vue';
import GroupRecertification from '@/components/groups/details/GroupRecertification.vue';
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ActionMethod } from '@/helpers/typeHelpers';
import { GroupsActions } from '@/store/groups/actions.groups';
import { FetchCertificationGroupQueryVariables } from '@/generated/graphql';
import { Getter } from 'vuex-class';
import { GroupsGetter } from '@/store/groups/getter.groups';
import { CertificationGroupFrontEnd } from '@/store/groups/groups';

@Component({
  components: { GroupOverview, IntegrationsSearch, GroupIntegrationsTable, GroupRecertification },
})
export default class GroupDetails extends Vue {
  @Getter(`groups/${GroupsGetter.GET_GROUP}`)
  group!: CertificationGroupFrontEnd;

  @Getter(`groups/${GroupsGetter.GET_GROUP_LOADING}`)
  groupLoading!: boolean;

  @Action(`groups/${GroupsActions.FETCH_GROUP}`)
  performFetchGroup!: ActionMethod<FetchCertificationGroupQueryVariables, void>;

  created() {
    const routeId = parseInt(this.$route.params.groupId);
    this.performFetchGroup({ id: routeId });
  }

  mounted() {
    //@ts-ignore
    this.$gtag.pageview('/group-details');
  }
}
