





























import Vue from 'vue';
import EditModal from '@/components/groups/details/EditModal.vue';
import AddExistingModal from '@/components/groups/details/AddExistingModal.vue';
import { ROUTER } from '@/constants';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { GroupsGetter } from '@/store/groups/getter.groups';
import { CertificationGroup } from '@/generated/graphql';

@Component({ components: { EditModal, AddExistingModal } })
export default class GroupOverview extends Vue {
  @Getter(`groups/${GroupsGetter.GET_GROUP}`)
  group!: CertificationGroup;

  addNew() {
    this.$router.push({ name: ROUTER.INTEGRATIONS });
  }

  openDialog(method: () => void) {
    setTimeout(() => method());
  }
}
