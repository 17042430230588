var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-6",attrs:{"color":"white","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"data-cy":"test-group-menu"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('add-existing-modal',{attrs:{"group":_vm.group},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var open = ref.open;
return [_c('v-list-item',{attrs:{"data-cy":"test-group-addExisting"},on:{"click":function($event){return _vm.openDialog(open)}}},[_vm._v(" "+_vm._s(_vm.$t('groups.addExisting'))+" ")])]}}])}),_c('edit-modal',{attrs:{"group":_vm.group},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var open = ref.open;
return [_c('v-list-item',{attrs:{"data-cy":"test-group-editIcon"},on:{"click":function($event){return _vm.openDialog(open)}}},[_vm._v(" "+_vm._s(_vm.$t('groups.edit'))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }