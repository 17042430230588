var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.integrations,"footer-props":{
        itemsPerPageOptions: [10, 20, 30],
      },"item-key":"id","show-select":"","sort-by":"marketViewEntity.parentName","dense":"","data-cy":"test-integration-table"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:_vm.itemClass(item),attrs:{"data-cy":"test-integration-name"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.appId",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:_vm.itemClass(item),attrs:{"data-cy":"test-integration-appId"}},[_vm._v(_vm._s(item.appId))])]}},{key:"item.individualState",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:item.individualState == 'certified' ? '--production' : null,attrs:{"data-cy":"test-integration-status"}},[_vm._v(_vm._s(item.individualState))])]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }