








import { CertificationGroup } from '@/generated/graphql';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import AcknowledgementDialog from '@/components/integrations/workflow/AcknowledgementDialog.vue';
import SelectForRecert from '@/components/groups/details/SelectForRecert.vue';
import { GroupsGetter } from '@/store/groups/getter.groups';

@Component({
  components: { AcknowledgementDialog, SelectForRecert },
})
export default class GroupRecertification extends Vue {
  $refs!: {
    acknowledgement: HTMLFormElement;
    selection: HTMLFormElement;
  };

  @Getter(`groups/${GroupsGetter.GET_RECERT_LOADING}`)
  loading!: boolean;

  @Prop()
  ackKey!: string;

  @Prop({ default: 'workflow.transitionDialog.activator' })
  btnText!: string;

  @Getter(`groups/${GroupsGetter.GET_GROUP}`)
  group!: CertificationGroup;

  showDialog = true;

  get accepted() {
    return true;
  }

  open() {
    this.$refs.acknowledgement.acknowledgementLastClicked = { event: '', state: 'planned' };
    if (!this.$refs.acknowledgement.acknowledgementResponse) {
      this.$refs.acknowledgement.acknowledgementDialog = true;
      return;
    }
  }

  moveToRecertify() {
    this.$refs.acknowledgement.acknowledgementResponse = false;
    this.$refs.acknowledgement.acknowledgementDialog = false;
    this.$refs.selection.dialog = true;
  }
}
