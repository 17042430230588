
























































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import type { ActionMethod } from '@/helpers/typeHelpers'
import { TreeNode, Country, FetchOwlTreeNodesQueryVariables, FetchDogCountriesQuery, FetchDogCountriesQueryVariables } from '@/generated/graphql';
import { Action, Getter } from 'vuex-class';
import { CoreGetter } from '@/store/core/getter.core';
import { CoreActions } from '@/store/core/actions.core';
import { GroupsActions } from '@/store/groups/actions.groups';
import { GroupsGetter } from '@/store/groups/getter.groups';
import { getOsArray } from '@/helpers/osHelper'
import { getProductArray } from '@/helpers/productHelper';
import { SEARCH_MIN_CHARACTER } from "@/constants"

export type Selected = {
  osType: string;
  region: string;
  product: string;
  marketViewEntity: TreeNode|null;
  searchStr: string;
  status: string;
}

@Component({
  components: {},
  methods: { getOsArray, getProductArray },
})
export default class Search extends Vue {
  @Prop({
    required: false,
    default: () => false,
  })
  isGroupSearch!: boolean;

  selected: Selected = {
    osType: '',
    region: '',
    product: '',
    marketViewEntity: null,
    searchStr: '',
    status: 'active'
  }

  marketViewEntities: TreeNode[] = [];

  clientId = ''

  searchTimeout = null as NodeJS.Timeout | null // eslint-disable-line

  @Watch('clientId')
  onClientIdChange(to: string) {
    if (to !== null && to.length >= SEARCH_MIN_CHARACTER) {
      if (this.searchTimeout != null) {
        clearTimeout(this.searchTimeout)
      }
      this.searchTimeout = setTimeout(async () => {
        this.searchMVEntities()
        clearTimeout(this.searchTimeout!)
        this.searchTimeout = null
      }, 1000)
    }
  }

  @Watch('selected', { deep: true })
  onPropertyChanged(value: Selected) {
    this.$emit('search', value)
  }

  @Getter(`core/${CoreGetter.GET_COUNTRIES}`)
  countries!: Country[]

  @Getter(`groups/${GroupsGetter.GET_MVE_LOADING}`)
  mveLoading!: boolean;

  @Action(`core/${CoreActions.FETCH_COUNTRIES}`)
  performFetchCountries! :ActionMethod<FetchDogCountriesQueryVariables, FetchDogCountriesQuery>

  @Action(`groups/${GroupsActions.FETCH_MARKET_VIEW_ENTITIES}`)
  performFetchMarketViewEntities! :ActionMethod<FetchOwlTreeNodesQueryVariables, TreeNode[]>

  async mounted(){
    this.performFetchCountries();
  }

  // @ts-ignore
  /* eslint-disable @typescript-eslint/no-explicit-any */
  updateSearchStr(val: any) {
    this.selected.searchStr = val.target.value;
  }

  async searchMVEntities() {
    const payload: FetchOwlTreeNodesQueryVariables = {
      q: this.clientId,
      // type: [Scope.Parent],
    };
    this.marketViewEntities = await this.performFetchMarketViewEntities(payload)

    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('search_mv_entities', {
      event_category: 'group',
      event_label: 'search mv entities',
    });
  }
}
