













import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import NiShow from '@nielsen-media/nlsn-vue-ui/lib/components/NiShow.vue';

@Component({ components: { NiShow } })
export default class IntegrationsSearch extends Vue {}
