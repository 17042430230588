var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.computedHeaders,"loading":_vm.loading,"items":_vm.integrations,"sort-by":"state","footer-props":{
        itemsPerPageOptions: [15, 30, 50],
      }},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{key:props.item.id,staticClass:"application-row",attrs:{"data-cy":"test-result-row"},on:{"click":function($event){return _vm.openDetail(props.item.id)}}},[_c('td',{staticClass:"text-xs-left table-col"},[_c('v-tooltip',{attrs:{"disabled":_vm.$vuetify.breakpoint.lgAndUp,"top":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({attrs:{"data-cy":"test-result-name"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(props.item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.appId))])])],1),(_vm.$vuetify.breakpoint.lgAndUp)?_c('td',{staticClass:"text-xs-left table-col",attrs:{"data-cy":"test-result-appid"}},[_vm._v(" "+_vm._s(props.item.appId)+" ")]):_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp)?_c('td',{staticClass:"text-xs-left table-col",attrs:{"data-cy":"test-result-region"}},[_vm._v(" "+_vm._s(props.item.region.toUpperCase())+" ")]):_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp)?_c('td',{staticClass:"text-xs-left table-col",attrs:{"data-cy":"test-result-os"}},[_vm._v(" "+_vm._s(_vm.$t(("osNames." + (props.item.os))))+" ")]):_vm._e(),_c('td',{staticClass:"text-xs-left table-col",attrs:{"data-cy":"test-result-parent"}},[_vm._v(" "+_vm._s(props.item.marketViewEntity != null ? props.item.marketViewEntity.parentName : props.item.parentName)+" ")]),_c('td',{staticClass:"text-xs-left table-col",attrs:{"data-cy":"test-result-clientid"}},[_vm._v(" "+_vm._s(props.item.marketViewEntity != null ? props.item.marketViewEntity.clientId : props.item.clientId)+" ")]),_c('td',{staticClass:"text-xs-left table-col",attrs:{"data-cy":"test-result-c6"}},[_vm._v(" "+_vm._s(props.item.marketViewEntity != null ? props.item.marketViewEntity.c6 : props.item.c6)+" ")]),_c('td',{staticClass:"text-xs-left table-col",attrs:{"data-cy":"test-result-state"}},[_c('span',{class:props.item.individualState == 'certified' ? '--production' : null},[_vm._v(_vm._s(props.item.individualState))])]),_c('td',{staticClass:"text-md-left table-col pr-0",attrs:{"data-cy":"test-result-product"}},[(props.item.workflows && props.item.workflows.length)?_c('div',_vm._l((_vm.humanize(props.item.workflows)),function(product,i){return _c('span',{key:i},[_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({class:_vm.getCssClassFromState(product.state),attrs:{"x-small":"","data-cy":"test-result-product"},on:{"click":function($event){$event.stopPropagation();return _vm.openProduct(props.item.id, product.codeProduct)}}},'v-chip',attrs,false),on),[_vm._v(_vm._s(product.product)+" ")])]}}],null,true)},[_c('span',{staticClass:"hover-text"},[_vm._v(_vm._s(_vm.$t(("smStates." + (product.state)))))])])],1)}),0):_c('div',{staticClass:"no-workflows"},[_c('v-chip',{staticClass:"start-cert-chip",attrs:{"x-small":"","data-cy":"test-result-startcert"}},[_vm._v(" "+_vm._s(_vm.$t("startCertification"))+" ")])],1)]),_c('td',{staticClass:"px-0",attrs:{"data-cy":"test-integration-menu"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-6",attrs:{"color":"white","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.removeIntegration(props.item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('groups.remove'))+" ")])],1)],1)],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }