


























import Vue from 'vue';
import Component from 'vue-class-component';
import { ROUTER } from '@/constants';
import { getCssClassFromState } from '@/helpers/integrationHelper';
import {} from '@/helpers/reportingHelper';
import { Getter } from 'vuex-class';
import { GroupsGetter } from '@/store/groups/getter.groups';
import { CertificationGroupFrontEnd } from '@/store/groups/groups';

@Component({ methods: { getCssClassFromState } })
export default class GroupSearchResultsTable extends Vue {
  @Getter(`groups/${GroupsGetter.GET_GROUPS}`)
  groups!: CertificationGroupFrontEnd[] | null;

  @Getter(`groups/${GroupsGetter.GET_GROUPS_LOADING}`)
  loading!: boolean;

  headers = [
    {
      text: this.$t('groups.name'),
      align: 'start',
      sortable: false,
      value: 'name',
      divider: false,
    },
    {
      text: this.$t('groups.parent'),
      align: 'start',
      sortable: false,
      value: 'marketViewEntity.name',
      divider: false,
    },
    // {
    //   text: this.$t('groups.contact'),
    //   align: 'start',
    //   sortable: false,
    //   value: 'contact.name',
    //   divider: false,
    // },
    {
      text: this.$t('currentState'),
      align: 'start',
      sortable: false,
      value: 'certificationState',
      divider: false,
    },
  ];

  handleRowClick(row: { id: number }) {
    this.$router.push({ name: ROUTER.GROUP_DETAILS, params: { groupId: row.id.toString() } });

    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('open_group_details', {
      event_category: 'groups',
      event_label: 'open group from group table',
    });
  }
}
