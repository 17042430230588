

































































































import Vue from 'vue';
import IntegrationsSearch from '@/components/groups/common/Search.vue';
import IntegrationsTable from '@/components/groups/common/IntegrationsTable.vue';
import FullScreenLoading from '@/components/common/FullScreenLoading.vue';
import { ROUTER } from '@/constants';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { GroupsActions } from '@/store/groups/actions.groups';
import { GroupsGetter } from '@/store/groups/getter.groups';
import { ActionMethod } from '@/helpers/typeHelpers';
import {
  CertificationGroup,
  Integration,
  // FetchAllUsersForHierarchyQueryVariables,
  FetchIntegrationsQueryVariables,
  CreateCertificationGroupMutationInput,
  // UserAssignment,
} from '@/generated/graphql';
import { CertificationGroupFrontEnd } from '@/store/groups/groups';

@Component({
  components: { IntegrationsSearch, IntegrationsTable, FullScreenLoading },
})
export default class CreateOrEditModal extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */
  @Prop({
    required: false,
    default: () => ({}),
  })
  group!: CertificationGroupFrontEnd;

  stepNumber = 1;
  dialog = false;
  name = '';
  // contact = null;
  ratio = null;
  selected: Integration[] = [];
  showWarning = false;
  loading = false;
  ratios = [
    { name: '10%', code: 0.1 },
    { name: '20%', code: 0.2 },
    { name: '30%', code: 0.3 },
    { name: '40%', code: 0.4 },
    { name: '50%', code: 0.5 },
    { name: '60%', code: 0.6 },
    { name: '70%', code: 0.7 },
    { name: '80%', code: 0.8 },
    { name: '90%', code: 0.9 },
    { name: '100%', code: 1.0 },
  ];

  mounted() {
    this.performFetchIntegrations({ searchStr: '', status: 'active', first: 15 });
  }

  // @Getter(`groups/${GroupsGetter.GET_USERS_FROM_HEN}`)
  // henUsers!: UserAssignment[];

  @Getter(`groups/${GroupsGetter.GET_INTEGRATIONS}`)
  integrations!: Integration[] | null;

  // @Action(`groups/${GroupsActions.FETCH_ALL_USERS_FROM_HIERARCHY}`)
  // fetchContacts!: ActionMethod<FetchAllUsersForHierarchyQueryVariables, void>;

  @Action(`groups/${GroupsActions.FETCH_INTEGRATIONS}`)
  performFetchIntegrations!: ActionMethod<FetchIntegrationsQueryVariables, void>;

  @Action(`groups/${GroupsActions.CREATE_GROUP}`)
  createGroup!: ActionMethod<CreateCertificationGroupMutationInput, void>;

  // get contacts() {
  //   return this.henUsers?.map(user => user.user);
  // }

  get selectedIntegrations() {
    return this.selected?.map(integration => ({ id: Math.floor(integration.id) }));
  }

  get multipleTreeIdsSelected() {
    return this.selectedMarketViewEntities.length > 1;
  }

  get selectedMarketViewEntities() {
    const mve = this.selected.reduce((acc, integration) => {
      const parentTreeId = integration?.marketViewEntity?.parentTreeId;
      if (parentTreeId) {
        const id = Math.floor(parentTreeId);
        if (acc.indexOf(id) == -1) {
          acc.push(id);
        }
      }
      return acc;
    }, [] as number[]);
    return mve;
  }

  get canCreate() {
    return this.name && this.ratio;
    // return this.name && this.contact && this.ratio;
  }
  timeout: NodeJS.Timeout | null = null;

  searchIntegrations(filters: FetchIntegrationsQueryVariables) {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.timeout = setTimeout(() => {
      this.performFetchIntegrations(filters);
    }, 3000);
  }

  updateSelected(items: Integration[]) {
    this.selected = items;

    // if (!this.multipleTreeIdsSelected && this.selected.length) {
    //   this.fetchContacts({ marketViewEntity: { id: this.selectedMarketViewEntities[0] } });
    // }

    if (this.multipleTreeIdsSelected) {
      this.showWarning = true;
    }
  }

  open() {
    this.dialog = true;
  }

  create() {
    this.loading = true;
    this.createGroup({
      name: this.name,
      // contact: this.contact,
      ratio: this.ratio,
      integrations: this.selectedIntegrations,
      marketViewEntity: { id: this.selectedMarketViewEntities[0] },
    })
      .then((cg: CertificationGroup | void) => {
        this.loading = false;
        if (cg && cg.id) {
          this.$router.push({ name: ROUTER.GROUP_DETAILS, params: { groupId: cg.id.toString() } });
        }
      })
      .catch(() => {
        this.loading = false;
      });
    this.dialog = false;

    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('create_group', {
      event_category: 'groups',
      event_label: 'create new group',
    });
  }
}
