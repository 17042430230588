






























































































import Vue from 'vue';
import { ROUTER } from '@/constants';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ComputedHeaders } from '@/store/search/search';
import { getCssClassFromState } from '@/helpers/integrationHelper';
import { ReducedWorkflow } from '@/store/workflow/workflow';
import { Breakpoint } from 'vuetify/types/services/breakpoint';
import {
  TreeNode,
  RemoveIntegrationsFromCertificationGroupMutationInput,
  Workflow,
  FetchCertificationGroupQueryVariables,
} from '@/generated/graphql';
import { Action } from 'vuex-class';
import { ActionMethod } from '@/helpers/typeHelpers';
import { GroupsActions } from '@/store/groups/actions.groups';
import { CertificationGroupFrontEnd } from '@/store/groups/groups';

@Component({ components: {}, methods: { getCssClassFromState } })
export default class IntegrationsTable extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */
  @Prop({
    required: false,
    default: () => [],
  })
  integrations!: TreeNode[];

  @Prop({
    required: true,
    default: () => ({}),
  })
  group!: CertificationGroupFrontEnd;

  loading = false;

  headers = [
    { text: this.$t('groups.integrationName'), value: 'name', align: 'start', sortable: false },
    { text: this.$t('groups.appId'), value: 'appId', align: 'start', sortable: false, hide: 'lgAndUp' },
    { text: this.$t('groups.country'), value: 'country', align: 'start', sortable: false, hide: 'lgAndUp' },
    { text: this.$t('groups.os'), value: 'os', align: 'start', sortable: false, hide: 'lgAndUp' },
    { text: this.$t('groups.parent'), value: 'parentName', align: 'start', sortable: false },
    { text: this.$t('groups.clientId'), value: 'clientId', align: 'start', sortable: false, width: 100 },
    { text: this.$t('groups.c6'), align: 'start', sortable: false, value: 'c6' },
    { text: this.$t('groups.individualState'), value: 'state', sortable: true, align: 'start', width: 120 },
    { text: this.$t('groups.certifications'), value: 'certifications', align: 'start', sortable: false },
    { text: '', value: '', sortable: false, align: 'right', width: 10 },
  ];

  @Action(`groups/${GroupsActions.REMOVE_INTEGRATION_FROM_GROUP}`)
  removeIntegrationFromGroup!: ActionMethod<RemoveIntegrationsFromCertificationGroupMutationInput, void>;

  @Action(`groups/${GroupsActions.FETCH_GROUP}`)
  performFetchGroup!: ActionMethod<FetchCertificationGroupQueryVariables, void>;

  get computedHeaders(): ComputedHeaders[] {
    const bp = this.$vuetify.breakpoint;
    return this.headers.filter(h => {
      return !h.hide || bp[h.hide as keyof Breakpoint];
    });
  }

  openDetail(id: string) {
    this.$router
      .push({
        name: ROUTER.WORKFLOW_LIST,
        params: { integrationId: id },
      })
      .catch(err => console.error(err));

    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('open_integration_from_group_table', {
      event_category: 'integration',
      event_label: 'open integration from group result table',
      value: id,
    });
  }

  openProduct(id: string, product: string) {
    this.$router
      .push({
        name: ROUTER.WORKFLOW_DETAILS,
        params: {
          integrationId: id.toString(),
          product: product.toString(),
        },
      })
      .catch(err => console.error(err));

    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('open_workflow_from_group_table', {
      event_category: 'integration_workflow',
      event_label: 'open workflow from group result table',
      value: `${id}_${product}`,
    });
  }

  humanize(workflows: Workflow[]): ReducedWorkflow[] {
    // removes duplicate products from recertification workflows
    // and allows for proper reusuable naming conventions/references
    // for colors/i18n
    const unique = workflows
      .slice()
      .sort((a, b) => a.id - b.id)
      .reduce((acc: ReducedWorkflow[], cur) => {
        const product = this.$t(`products.${cur.product}`);
        const index = acc.findIndex(c => c.product === product);

        if (index > -1) {
          acc[index].state = cur.smState;
        } else {
          const obj = {
            product: product, // for css display (dcr_static -> DCR Static)
            codeProduct: cur.product, // for query param value in link (integration/:integration_id/:product)
            state: cur.smState,
          };
          acc.push(obj);
        }

        return acc;
      }, []);
    return unique;
  }

  async removeIntegration(id: number) {
    await this.removeIntegrationFromGroup({ id: this.group.id, integrations: [{ id: id }] });
    this.performFetchGroup({ id: this.group.id });

    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('remove_integration', {
      event_category: 'groups',
      event_label: 'remove integration from group',
      value: this.group.id,
    });
  }
}
