


































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getCssClassFromState } from '@/helpers/integrationHelper';
import { Getter } from 'vuex-class';
import { GroupsGetter } from '@/store/groups/getter.groups';
import { TreeNode } from '@/generated/graphql';

@Component({ components: {}, methods: { getCssClassFromState } })
export default class IntegrationsTable extends Vue {
  @Prop({
    required: false,
    default: () => [],
  })
  selected!: TreeNode[];

  @Prop({
    required: false,
    default: () => [],
  })
  integrations!: TreeNode[];

  @Prop({
    required: false,
    default: () => [],
  })
  highlight!: number[];

  @Getter(`groups/${GroupsGetter.GET_INTEGRATIONS_LOADING}`)
  loading!: boolean;

  get selectedItems() {
    return this.selected;
  }

  set selectedItems(items: TreeNode[]) {
    this.$emit('update', items);
  }

  itemClass(item: TreeNode) {
    const highlighted = this.highlight.includes(item.id);
    const result = highlighted ? 'primaryButtonColor--text' : null;
    return result;
  }

  headers = [
    {
      text: this.$t('createIntegration.applicationTable.headers.integrationName'),
      value: 'name',
      align: 'start',
      sortable: false,
    },
    { text: this.$t('groups.appId'), value: 'appId', align: 'start', sortable: false },
    { text: this.$t('groups.parent'), value: 'marketViewEntity.parentName', align: 'start', sortable: false },
    { text: this.$t('groups.clientId'), value: 'marketViewEntity.clientId', align: 'start', sortable: false },
    { text: this.$t('groups.c6'), value: 'marketViewEntity.c6', align: 'start', sortable: false },
    { text: this.$t('groups.individualState'), value: 'individualState', align: 'start', sortable: false },
  ];
}
