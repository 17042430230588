





































import Vue from 'vue';
import NiShow from '@nielsen-media/nlsn-vue-ui/lib/components/NiShow.vue';
import DetailsMenu from '@/components/groups/details/Menu.vue';
import GroupRecertification from '@/components/groups/details/GroupRecertification.vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { GroupsGetter } from '@/store/groups/getter.groups';
import { getCssClassFromState } from '@/helpers/integrationHelper';
import { CertificationGroup, Recertifiability } from '@/generated/graphql';

@Component({ components: { NiShow, DetailsMenu, GroupRecertification }, methods: { getCssClassFromState } })
export default class GroupOverview extends Vue {
  @Getter(`groups/${GroupsGetter.GET_GROUP}`)
  group!: CertificationGroup;

  get isRecertifiable() {
    return this.group.recertifiable == Recertifiability.Recertifiable;
  }

  get certifiedIntegrations() {
    const integrationsCertified = this.group.integrations.filter(
      integration => integration.individualState == 'certified'
    );
    return integrationsCertified?.length;
  }

  get recertConfirmKey() {
    return 'workflow.transitionDialog.recertConfirmTextGroup';
  }
}
